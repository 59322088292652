import { FC } from "react";

import { Icon, IconProps } from "@chakra-ui/icons";

const CustomIcon: FC<Omit<IconProps, "css">> = ({ children, ...props }) => (
  <Icon x="0px" y="0px" width="95.333px" height="95.333px" viewBox="0 0 95.333 95.333" fill="none" {...props}>
    <path
      d="M93.333,0H2C0.896,0,0,0.896,0,2v91.332c0,1.104,0.896,2,2,2h48.525V63.477H40.284c-0.885,0-1.602-0.717-1.602-1.602
    V48.961c0-0.885,0.717-1.603,1.602-1.603h10.242v-8.913c0-11.744,7.395-19.332,18.839-19.332l10.188,0.016
    c0.883,0.001,1.6,0.718,1.6,1.602v11.991c0,0.885-0.717,1.602-1.602,1.602l-6.863,0.003c-4.729,0-5.595,1.844-5.595,5.475v7.556
    h11.979c0.425,0,0.832,0.169,1.133,0.47c0.3,0.301,0.469,0.708,0.469,1.133L80.67,61.876c0,0.885-0.717,1.603-1.602,1.603H67.092
    v31.854h26.24c1.104,0,2-0.896,2-2V2C95.333,0.895,94.438,0,93.333,0z"
      fill="#ffffff"
    />
  </Icon>
);

export default CustomIcon;
